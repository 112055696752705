function MM_jumpMenu(targ, selObj, restore) { //v3.0
  eval(targ + ".location='" + selObj.options[selObj.selectedIndex].value + "'");
  if (restore) selObj.selectedIndex = 0;
}
jQuery(document).ready(function () {
  if (document.cookie.indexOf('showdisclaimercht=false') == -1) {
    var oneYear = 1000 * 60 * 60 * 24 * 90;
    var expires = new Date((new Date()).valueOf() + oneYear);
    document.cookie = "showdisclaimercht=true; expires=" + expires.toUTCString() + "; path=/";
    $.colorbox({
      inline: true,
      width: "90%",
      href: "#inline_content",
      top: "5%"
    });
  }
});
function hideDisclaimer() {
  var date = new Date();
  date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
  var expires = "; expires=" + date.toGMTString();
  document.cookie = "showdisclaimercht=false" + expires + "; path=/";
}
function open_win() {
  // window.open("/app/")
}
function open_win2() {
  location.href = 'accessportfolio.html';
}
function open_win3() {
  // window.open("/csr/")
}

// google translate
function googleTranslateElementInit() {
  new google.translate.TranslateElement({
    pageLanguage: 'en',
    includedLanguages: 'en,ja,zh-CN,zh-TW,ko,pt,it,de,ru,fr',
    multilanguagePage: true
  }, 'google_translate_element');
}

function triggerHtmlEvent(element, eventName) {
  var event;
  if (document.createEvent) {
    event = document.createEvent('HTMLEvents');
    event.initEvent(eventName, true, true);
    element.dispatchEvent(event);
  } else {
    event = document.createEventObject();
    event.eventType = eventName;
    element.fireEvent('on' + event.eventType, event);
  }
}